import { OnInit } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { AppInjector } from 'src/app/providers/app-injector.service';

export class BaseListPage {
	items: any = [];	
	hasItems: boolean = false;
	loader: any;
	filterCategory: any;
	serverError: any = "";
	loadingMsg: any = "Loading...";
	isRefreshing: boolean = false;
	isFetchingMore: boolean = false;
	isLoading: boolean = false;
	isSearching: boolean = false;
	lastRequestReturnedValues: boolean = false;	
	toDeleteId: any;
	
	page: any = 1;
	size: any = 10;
	length: any = 0;

	isFirstLoad: boolean = true;
	currentPageNumber: any = 0;
	totalPages: any = 0;
	pageSize: any = 10;
	itemsLeft: boolean = true;
	loaingNextPage: boolean = false;
	newChildAdded: boolean = false;
	pagingValue: any;
	pagingValueField: any;

	loadedFirstTime: boolean = false;

	saving: {
		isSaving: boolean,
		action: any
	};

	protected toastController: ToastController;

	load(cb){}

	constructor(
	){
		this.toastController = AppInjector.injector.get(ToastController); 

		this.working = {
			isWorking: false,
			action: ''
		};
	}
	
	workingItem: any;

	working: {
		isWorking: boolean,
		action: any
	}

	isSaving(){
		return this.saving.isSaving;
	}

	startSaving(action){
		this.serverError = "";
		
		this.saving = {
			isSaving: true,
			action: action
		};
	}

	stopSaving(){
		this.saving = {
			isSaving: false,
			action: ""
		};		
	}

	isWorking(){
		return this.working.isWorking;
	}	

	startWorking(action){
		this.working = {
			isWorking: true,
			action: action
		};
	}

	stopWorking(){
		this.working = {
			isWorking: false,
			action: ""
		};		
	}	

	params: any = {
		page: 1,
		size: 10,
		id: null,
		cbAfter: null,
		cb: (items, list)=>{
			this.isLoading = false;

			this.hasItems = (items.length == 0 && this.params.page == 1) ? false : true;

			this.lastRequestReturnedValues = (items.length==0) ? false : true;
			this.currentPageNumber = this.hasItems ? items[0].PageNumber : 0;
			this.totalPages = this.hasItems ? items[0].TotalPages : 0;

			if(this.params.page == 1){
				list = items;	
			}else{
				for (var i = items.length - 1; i >= 0; i--) {
					list.push(items[i]);
				}	
			}
			
			this.items = list;

			this.isLoading = false;

			if(typeof this.params.cbAfter == "function")
				this.params.cbAfter()			
		}
	}

	showsPager(service){
		var returnVal = false;

		try{
			if(service.currentPageNumber < service.totalPages){
				returnVal = true;
			}	
		}catch(e){}
		
		return returnVal;
	}

	showMore(service){
		service.page++;

		//this.load();
	}

	snapshotSingleItem(snapshot) {
		let returnArr = [];

		returnArr.push(snapshot.val());
	
		return returnArr
	}

	snapshotToArray(snapshot) {
	    let returnArr = [];
	    var count = 0;
    	
	    this.pagingValue = "";
	    this.itemsLeft = true;

	    snapshot.forEach(childSnapshot => {
	        let item = childSnapshot.val();
	        item.key = childSnapshot.key;
	        count++;

	        returnArr.push(item);

	        if(this.pagingValueField!="" && this.pagingValue==""){
				this.pagingValue = item[this.pagingValueField];
	        }	        
	    });	

	    if(this.loaingNextPage){
	    	returnArr = returnArr.reverse();
	    	this.loaingNextPage = false;
	    }

	    if((count<(this.pageSize-1)) || count==0){
	    	this.itemsLeft = false;
	    }


	    return returnArr;
	}	

	addItemsToArray(baseArray, itemsToAddArray, skipFirst, isNewItem){
		let items = (isNewItem) 
					?
					this.snapshotSingleItem(itemsToAddArray)
					:
					this.snapshotToArray(itemsToAddArray);

		var skipedFirst = false;

		for (var item of items) {
			if(skipFirst && !skipedFirst){
				skipedFirst = true;
			}else{
				if(isNewItem){
					baseArray.push(item);				
				}else{
					baseArray.unshift(item);					
				}				
			}
		}

		console.log("addItemsToArray!")	
	}

	manageMatPager(data, paginator){
		if(data.length>0){
			var dataItem = data[0];

			this.page = dataItem.PageNumber;
			this.length =  dataItem.TotalRows;
		}
	}

	async presentToast(msg) {
		const toast = await this.toastController.create({
			message: msg,
			duration: 2000
		});
		toast.present();
	}		
};